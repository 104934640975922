import './bootstrap';
import '../css/app.scss';

import { createApp, h, DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'SRDN Partner Platform';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy);
            
        Sentry.init({
            app: vueApp,
            dsn: "https://a56d1a98e5c6ca6d1b8bcfce99f81812@errors.rds-online.nl/5",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    blockAllMedia: false,
                    maskAllText: false,
                    maskAllInputs: true,
                }),
            ],
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            allowUrls: ["srdn.nl"],
            beforeSend(event) {
                if (
                    event.exception?.values?.[0]?.stacktrace?.frames?.some(
                        (f) =>
                            f.filename?.includes(
                                `https://www.googletagmanager.com`,
                            ),
                    )
                ) {
                    return null;
                }

                return event;
            },
        });

        vueApp.mount(el);

        return vueApp;
    },
    progress: {
        color: '#4B5563',
    },
});
